import React, { memo } from "react";
import { arrayOf, object } from "prop-types";
import { connect } from "react-redux";

import CardImage from "../../atoms/CardImage";
import HeaderHeadline from "../../atoms/HeaderHeadline";

import { useDeviceType } from "../../utils/react-utils";

import "./header-news.m.css";

const HeaderNews = ({ stories = [] }) => {
  const { tab, desktop } = useDeviceType();
  if (!(stories.length && (tab || desktop))) return null;

  return (
    <div styleName="base">
      {stories.slice(0, 3).map(({ story }) => (
        <div styleName="headline-item" key={story.id}>
          <CardImage
            aspectRatio={[1, 1]}
            imageType="image1x1"
            story={story}
            showStoryTypeIcon={false}
            className="card-with-image-zoom"
            linkEnable={true}
            widths={[110]}
          />
          <HeaderHeadline styleName="header-headline" story={story} />
        </div>
      ))}
    </div>
  );
};

HeaderNews.propTypes = {
  stories: arrayOf(object),
};

const mapStateToProps = (state) => ({
  stories: state["header-stories"],
});

export default connect(mapStateToProps)(memo(HeaderNews));
