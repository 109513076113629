import { renderComponent, renderIsomorphicComponent } from "@quintype/framework/client/start";

import { pickComponent } from "../isomorphic/pick-component";
import LoadingIndicatorComponent from "../isomorphic/components/atoms/loading-indicator";
import BreakingNewsSliderView from "../isomorphic/components/BreakingNewsSlider";

import TopAd from "../isomorphic/components/molecules/TopAd";
import Footer from "../isomorphic/components/layouts/footer/render-footer-component";
import Header from "../isomorphic/components/layouts/header/UniformHeader";
import InterstitialAd from "../isomorphic/components/molecules/InterstitialAd";
import WebInterstitialAdX from "../isomorphic/components/molecules/WebInterstitialAdx";
import GdprPolicy from "../isomorphic/components/molecules/GdprPolicy";
import AnchorAd from "../isomorphic/components/molecules/AnchorAd";
import HeaderCollection from "../isomorphic/components/molecules/HeaderCollection";

export function preRenderApplication(store) {
  const hydrate = { hydrate: !global.qtLoadedFromShell };
  renderComponent(Header, "header", store, hydrate);
  renderComponent(LoadingIndicatorComponent, "loading-indicator", store);
}

export function renderApplication(store) {
  const hydrate = { hydrate: !global.qtLoadedFromShell };
  renderComponent(HeaderCollection, "header-collection", store, hydrate);
  renderComponent(TopAd, "top-ad", store, hydrate);
  renderComponent(InterstitialAd, "interstitial-ad", store, hydrate);
  renderComponent(WebInterstitialAdX, "web-interstitial-adx", store, hydrate);

  const breakingNewsbaseProps = {
    ...hydrate,
    updateInterval: 120 * 1000,
  };
  renderComponent(BreakingNewsSliderView, "breaking-news-container", store, breakingNewsbaseProps);
  renderComponent(AnchorAd, "anchor-ad", store, hydrate);

  renderComponent(GdprPolicy, "gdpr-policy", store, hydrate);
  renderIsomorphicComponent("container", store, pickComponent, hydrate);
  renderComponent(Footer, "footer", store);
}
