import React from "react";
import { object, string } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import { Link } from "@quintype/components";

import { Facebook, Instagram, Twitter } from "../../../atoms/icons/StoryPageIcons";
import LinkedIn from "./LinkedIn";
import IconBase from "../../../icons";

import "./kishoraloFooter.m.css";

const KishoraloFooterBase = ({ currentPath, customSocialLinks, socialLinks }) => {
  const socialShareLinks = customSocialLinks.kishoralo || socialLinks;

  return (
    <div styleName="kishoralo-footer">
      <div styleName="social-links" className="print-none">
        <img
          // eslint-disable-next-line max-len
          src="https://media.prothomalo.com/prothomalo-bangla/2024-04/fd2023e4-ac17-49cb-b04f-79875bc4f10b/kia_footer.jpg?dpr=1.0"
          alt="kishor alo"
          styleName="kia-footer-img"
        />
        <div styleName="first-three-icons">
          {socialShareLinks["facebook-url"] && (
            <Link
              aria-label="Kishoralo Facebook Link"
              href={socialShareLinks["facebook-url"]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Facebook color1="#001246" color2="#ffffff" />
            </Link>
          )}
          {socialShareLinks["twitter-url"] && (
            <Link
              aria-label="Kishoralo Twitter Link"
              href={socialShareLinks["twitter-url"]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Twitter color1="#001246" color2="#ffffff" />
            </Link>
          )}
          {socialShareLinks["instagram-url"] && (
            <Link
              aria-label="Kishoralo Instagram Link"
              href={socialShareLinks["instagram-url"]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram />
            </Link>
          )}
          {socialShareLinks["linkedin-url"] && (
            <Link
              aria-label="Kishoralo Linkedin Link"
              href={socialShareLinks["linkedin-url"]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedIn color1="#001246" color2="#ffffff" />
            </Link>
          )}
        </div>
      </div>
      <div styleName="footer-details">
        <div styleName="logo">
          <a aria-label="Kishoralo logo" href="/">
            <IconBase type="kishoralo-white" alt="kishoralo" className="kishoralo-logo" />
          </a>
        </div>
        <div styleName="address" className="print-none">
          <span>ঠিকানা</span>
          <span>কিশোর আলো</span>
          <span styleName="line-3">১৯ কারওয়ান বাজার, ঢাকা ১২১৫</span>
          <span>ফোন : +৮৮-০২-৫৫০১৩৪৩০–৩৩</span>
          <span>ফ্যাক্স : +৮৮-০২-৫৫০১২২০০</span>
          <span>
            ই-মেইল:{" "}
            <a aria-label="editor@kishoralo.com" href="mailto:editor@kishoralo.com">
              editor@kishoralo.com
            </a>
          </span>
        </div>
        <div styleName="publisher" className="print-none">
          <span>প্রকাশক : মতিউর রহমান</span>
          <span>সম্পাদক : আনিসুল হক</span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentPath: get(state, ["currentPathReducer", "currentPath"], null),
  customSocialLinks: get(state, ["qt", "config", "custom-social-links"], {}),
  socialLinks: get(state, ["qt", "config", "social-links"], {}),
});

KishoraloFooterBase.propTypes = {
  currentPath: string,
  customSocialLinks: object,
  socialLinks: object,
};

export const KishoraloFooter = connect(mapStateToProps)(KishoraloFooterBase);

export default KishoraloFooter;
