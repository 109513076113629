import React from "react";
import { string } from "prop-types";
import { connect } from "react-redux";

import { DfpComponent } from "../../ads/dfp-component";
import DfpContainer from "../../ads/dfp-container";
import { getSlotId } from "../../utils/utils";
import { PAGE_TYPE, PREVIEW_PAGE_TYPES } from "../../../constants";

const WebInterstitialAdx = ({ currentPath, domainSlug }) => {
  if (domainSlug !== null) {
    return null;
  }

  return (
    <DfpContainer
      pages={[
        ...PREVIEW_PAGE_TYPES,
        PAGE_TYPE.HOME_PAGE,
        PAGE_TYPE.STORY_PAGE,
        PAGE_TYPE.AUTHOR_PAGE,
        PAGE_TYPE.UGC_PAGE,
        PAGE_TYPE.FOR_YOU,
        PAGE_TYPE.FOUR_TEN,
        PAGE_TYPE.POLL_COUNTER_PAGE,
      ]}
      className="web-interstitial-ad"
    >
      <DfpComponent type="Web_Interstitial_AdX" slotId={getSlotId("web-interstitial-adx", currentPath)} />
    </DfpContainer>
  );
};

WebInterstitialAdx.propTypes = {
  currentPath: string,
  domainSlug: string,
};

const mapStateToProps = (state) => ({
  currentPath: state?.qt?.currentPath,
  domainSlug: state?.qt?.config?.domainSlug,
});

export default connect(mapStateToProps)(WebInterstitialAdx);
